import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import ResumeCard from "./ResumeCard";
import downloadIcon from "../../assets/images/download.png";
import { Link } from "react-router-dom";
import myResume from "../../Hussain_Shaikh_Dev.pdf";
const Resume = () => {
  const {  resumeAchievements, resumeEduction, resumeExperience, skillsArray } = UseData();

  return (
    <>
      <PageTitle title="Resume"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container  sm:px-5 md:px-10 lg:px-20">
            <div className="py-12 px-4">
              {/*page title here */}
              <h2 className="after-effect after:left-44 mb-[40px] mt-12 lg:mt-0">
                Resume
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6 ">
                <ResumeCard items={resumeEduction} type="Education"/>
                <ResumeCard items={resumeExperience} type="Experience"/>
                <ResumeCard items={resumeAchievements} type="Achievements"/>
              </div>
            </div>
             {/* Dowanload button start */}
              <Link to={myResume} target="_blank" download>
                <button className="flex items-center bg-gradient-to-r from-[#00C5C8] to-[#70E7D1] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#00C5C8]  to-[#70E7D1] px-8 py-3 text-lg text-white rounded-[35px] mb-5">
                  <img src={downloadIcon} alt="icon" className="mr-2" />
                  Download CV
                </button>
              </Link>

              {/* Dowanload button End */}
          </div>
          {/* End education */}

          <div className="container bg-color-810 dark:bg-[#0D0D0D] py-12 px-4 sm:px-5 md:px-10 lg:px-20">
            <div className="grid grid-cols-1  md:grid-cols-2 gap-8">
              <div className="col-span-1">
                <h4 className="text-5xl dark:text-white font-medium mb-8">
                  Skills
                </h4>
                <div className="flex gap-x-3 gap-y-3 md:gap-y-6 md:gap-x-4 flex-wrap">
                  {skillsArray.map((skill, idx) => (
                    <button key={idx} className="resume-btn">{skill}</button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* End Skills */}

          {/* call footer */}
          <Footer condition={false} bg={"#FFFF"} />
        </div>
      </section>
    </>
  );
};

export default Resume;
