import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import Modal from "react-modal";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import { FiUser, FiCode, FiFilePlus, FiExternalLink } from "react-icons/fi";
import { BsXCircle } from "react-icons/bs";
import PageTitle from "../../Share/PageTitle";
import axios from "axios";
import { FaGithub } from "react-icons/fa";

Modal.setAppElement("#root");

const CommonPortfilo = ({ condition, items }) => {
  const {
    data,
    handleData,
    handleModelData,
    isOpen,
    setIsOpen,
    singleData,
  } = UseData();
  const [pokNFTs, setPokNFTs] = useState([]);
  const [test, setTest] = useState("All");
  const handleSearch = (text) => {
    handleData(text);
    setTest(text);
  };
  useEffect(() => {
    setTest("All");
    handleData("All");
  }, []);

  const handleModle = (id) => {
    handleModelData(id);
  };

  const item = condition ? 3 : 2;

  const breakpointColumnsObj = {
    default: item,
    1100: 2,
    500: 1,
  };

  useEffect(() => {
    if(test === "POK"){
      let fetch = true;
      (async ()=>{
       if(!pokNFTs.length){
        const res = await axios.get(`https://polygon-mainnet.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY_KEY}/getNFTs`, { params: { 
          owner:"0xC4975C18C83C39D46bf58Afb16667c51611A0789",
          contractAddresses: ["0x60576a64851c5b42e8c57e3e4a5cf3cf4eeb2ed6", "0x3cd266509d127d0eac42f4474f57d0526804b44e","0x33ffdc6f11ec030f99cc28657835372cfd41eb5c"]
        }});
        if(fetch){
          if(res.data && res.data.ownedNfts){
            setPokNFTs(res.data.ownedNfts);
          }
        }
       }
      })()
    }
    return () => {
      fetch = false;
    }
  },[test])


  return (
    <>
      <PageTitle title="Portfolio"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container mb-8   px-4 sm:px-5 md:px-10 lg:px-[60px]">
            <div className="py-12">
              <h2 className="after-effect  after:left-48  lg:mt-0">
                Portfolio
              </h2>

              {/* Portfilo fillter tab start */}

              <ul
                className={`${
                  item === 2 ? "mt-[30px] " : "mt-[40px]"
                } flex w-full justify-start md:justify-end  flex-wrap   font-medium`}
              >
                <li
                  className={`${
                    test === "All" ? "text-[#00C5C8]" : "fillter-btn "
                  } mr-4 md:mx-4`}
                  onClick={() => handleSearch("All")}
                >
                  All
                </li>
                <li
                  className={`${
                    test === "POK" ? "text-[#00C5C8]" : "fillter-btn"
                  } mr-4 md:mx-4`}
                  onClick={() => handleSearch("POK")}
                >
                  Proof Of Knowledge NFTs
                </li>
              </ul>

              {/* Portfilo fillter tab start */}
            </div>

            {/* Portfolio items start */}

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {(test==="POK" && pokNFTs.length) && 
                pokNFTs.map((nft) => {
                  const isImg = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(nft.metadata.image);
                  return (
                    <div
                    style={{
                      background: "transparent"
                    }}
                    className="rounded-lg p-6 dark:border-[2px] border-[#212425]"
                    key={nft.id.tokenId}
                    >
                      <div className=" overflow-hidden rounded-lg">
                        <figure>
                          {(!isImg) ? 
                            <video src={nft.metadata.image} className="rounded-sm css-66uqm4" autoPlay loop muted></video>
                          : 
                            <img src={nft.metadata.image}/>
                          }
                          
                        </figure>
                      </div>
                      <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
                        😎 ✌️
                      </span>
                      <h2
                        className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#00C5C8] dark:hover:text-[#70E7D1] dark:text-white mt-2"
                      >
                        {nft.metadata.name}
                      </h2>
                    </div>
                  )
                })
              }
              {(test==="All") ? data.slice(0, items).map((item) => (
                <div
                  style={{
                    background: "transparent",
                  }}
                  className="rounded-lg p-6 dark:border-[2px] border-[#212425]"
                  key={item.id}
                >
                  <div className=" overflow-hidden rounded-lg">
                    <img
                      onClick={() => handleModle(item.id)}
                      className="w-full    cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto "
                      src={item?.imgSmall}
                      alt=""
                    />
                  </div>
                  
                  {item.gitUrl && 
                     <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
                       <a
                         href={item.gitUrl}
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                       <FaGithub/>
                       </a>
                     </span>
                  }
             
                  <span className="pt-3 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
                    <a
                      href={item.tagUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    {item?.tag}
                    </a>
                  </span>
                  <h2
                    onClick={() => handleModle(item.id)}
                    className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#00C5C8] dark:hover:text-[#70E7D1] dark:text-white mt-2"
                  >
                    {item?.title}
                  </h2>
                </div>
              ))
            :<></>
            }
            </Masonry>

            {/* Portfolio items end */}

            {/* Portfolio modal start */}

            <Modal
              isOpen={isOpen}
              onRequestClose={() => setIsOpen(false)}
              className=" outline-none flex items-center  p-4 md:p-8  rounded-2xl my-8"
            >
              <div className=" w-full md:w-10/12 flex items-center   lg:w-[850px] bg-white dark:bg-[#323232] mx-auto rounded-xl p-4 md:p-8 absolute left-1/2 top-1/2 transform -translate-x-[50%] -translate-y-[50%] shadow-lg ">
                <div className=" overflow-y-scroll max-h-[80vh] no-scrollbar ">
                  {/* close button */}
                  <BsXCircle
                    onClick={() => setIsOpen(false)}
                    className="text-7xl cursor-pointer  absolute right-2 -top-12 md:-right-10 md:-top-6 z-50  text-white transition transform hover:rotate-45 duration-300 ease-in-out "
                  />
                  <h2 className="text-white dark:hover:text-slate-600 text-4xl text-center font-bold">
                    {singleData.title}
                  </h2>
                  <div className="grid grid-cols-1 lg:grid-cols-2 my-6">
                    <div className="space-y-2">
                      <p className="dark:text-white flex items-center text-[15px] sm:text-lg ">
                        <FiExternalLink className="text-lg mr-2 hidden sm:block" />
                        Preview :&nbsp;
                        <span className="font-medium transition-all duration-300 ease-in-out hover:text-slate-600 ">
                          <a
                            href={singleData?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {singleData?.linkText}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className="dark:text-white  text-2line font-normal text-[15px] sm:text-sm  ">
                    {singleData?.description}
                  </p>
                  <img
                    className="w-full md:h-[450px]  h-auto object-cover rounded-xl mt-6"
                    src={singleData.img}
                    alt=""
                  />
                </div>
              </div>
            </Modal>

            {/* Portfolio modal end */}
          </div>
          {/* End Portfolio */}

          {/* Common Footer call here*/}
          <Footer condition={true} bg={"#F8FBFB"} />
        </div>
      </section>
    </>
  );
};

export default CommonPortfilo;
