import React from "react";
import userImage from "../../assets/images/about/avatar1.jpg";
import { Link } from "react-router-dom";
import {
  FaGithub,
  FaTwitter,
  FaLinkedinIn,
  FaDownload
} from "react-icons/fa";
import {SiGumtree} from "react-icons/si";

import PageTitle from "../../Share/PageTitle";
import { FiCodesandbox } from "react-icons/fi";
import myResume from "../../Hussain_Shaikh_Dev.pdf";

const GITHUB = "https://github.com/Hussainzz";
const TWITTER = "https://twitter.com/shaikh_hu";
const LINKED_IN = "https://www.linkedin.com/in/hussain-shaikh-12106b103/";
const LENS = "https://www.lensfrens.xyz/shkhussain.lens";


const HomePage = () => {
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      {/* End pagetitle */}

      <div
        className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh]  xl:h-[71vh]  justify-center"
        data-aos="fade"
      >
        <img
          className="rounded-full w-[350px] h-[250px] 2xl:w-[280px] 2xl:h-[280px] imgSize"
          src={userImage}
          alt=""
        />
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
          Hussain Shaikh
        </h3>
        <p className="mb-1 text-[#7B7B7B]">Software Developer</p>
        <p className="mb-1 text-[#7B7B7B]">Web3 Enthusiast</p>
        {/* Avatar Info End */}

        {/* Social information start */}
        <div className="flex space-x-3">
          <a
            href={GITHUB}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1773EA]">
              <FaGithub />
            </span>
          </a>
          <a
            href={TWITTER}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#1C9CEA]">
              <FaTwitter />
            </span>
          </a>
          <a
            href={LENS}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#2edb28] ">
              <SiGumtree />
            </span>
          </a>
          <a
            href={LINKED_IN}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>
        </div>
        {/* Social information start */}

        <Link to="/works">
        <button className="flex items-center bg-gradient-to-r from-[#00C5C8] to-[#70E7D1] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#00C5C8]  to-[#70E7D1] px-8 py-2 text-lg text-zinc-900 rounded-md mt-6">
          <span className="pr-2"><FiCodesandbox /></span> View Work
          </button>
        </Link>
        <Link to={myResume} target="_blank" download>
            <button className="flex items-center bg-gradient-to-r from-[#00C5C8] to-[#70E7D1] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#00C5C8]  to-[#70E7D1] px-10 py-2 text-lg text-zinc-900 rounded-md mt-2 ">
              <span className="pr-2"><FaDownload /></span> Resume
            </button>
        </Link>
      </div>
    </>
  );
};

export default HomePage;
