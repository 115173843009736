import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/eaWorkBig.jpg";
import work2 from "../assets/images/work_images/ethB.jpg";
import work3 from "../assets/images/work_images/Lazy3.png";
import work4 from "../assets/images/work_images/GogoB.png";
import work5 from "../assets/images/work_images/multiSig.gif";

// works small images
import workSmall1 from "../assets/images/work_images/eaWork1.png";
import workSmall2 from "../assets/images/work_images/ethB.jpg";
import workSmall3 from "../assets/images/work_images/boo.png";
import workSmall4 from "../assets/images/work_images/GogoB.png";
import workSmall5 from "../assets/images/work_images/multiSig.gif";

// blog image small
import blogSmall4 from "../assets/images/blog_images/ethLottery.png";
import blogSmall1 from "../assets/images/blog_images/lazymint.png";

//  icon use as img here
import icon1 from "../assets/images/icons/icon-2.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaAward } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState("dark");
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    localStorage.setItem("theme", "dark");
    const themeValue = "dark";

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "Solidity, ReactJS, Hardhat, IPFS, NodeJS-Express",
      tagUrl:"https://devpost.com/Hussainzz",
      title: "Everyday Avatar",
      img: work1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      link: "https://www.everydayavatar.com/",
      linkText: "www.everydayavatar.com",
      description:
        "The Everyday Avatar is an NFT version of the paper doll, where for each Avatar, you have access to the entire collection of components to choose from while playing with your Avatar! Just like a real paper doll, you start with a base character and can swap out the accessories and components to your every whim, like the clothes, accessories, hairstyles, facial expressions, etc.",
    },
    {
      id: "2",
      tag: "Decentralized Lottery pure randomness using Chainlink VRF (Verifiable Random Function) - Solidity, Hardhat, ReactJS",
      tagUrl:"https://dev.to/hussainzz/eth-lottery-solidity-hardhat-3ebg",
      title: "Eth-Lottery",
      img: work2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      link: "https://eth-lottery-3glp1l5k0-hussainzz.vercel.app/",
      linkText: "Demo Here",
      description:
        "Few months ago i started reading about web3 technology and fell in love with ethereum and smart contracts. I decided to learn solidity to write smart contracts.After couple of weeks the first full fledged smart contract which i came across was Lottery Smart Contract which was just a basic smart contract which used to allow anyone to join the lottery by depositing some eth and the lottery Manager(owner of the lottery smart contract) would pick a random winner.But the approach which it took to pick the winner was not actually a true random winner.",
      gitUrl:"https://github.com/Hussainzz/Eth-Lottery"
    },
    {
      id: "3",
      tag: "Lazy Minting NFT - Solidity, Hardhat",
      tagUrl:"https://dev.to/hussainzz/lazy-minting-nft-solidity-hardhat-30l1",
      title: "Lazy Minting Dapp",
      img: work3,
      imgSmall: workSmall3,
      bg: "#FFF0F0",
      langages: "Solidity, Hardhat, ReactJS",
      link: "https://ghost-mint.vercel.app/",
      linkText: "Demo",
      description:
        " Lazy minting allows the owner to mint the nft tokens off-chain and then mint it on-chain only in case on a NFT purchase, this makes it easier and no-gas fees for the nft deployer.",
      gitUrl: "https://github.com/Hussainzz/lazy-mint-contract"
    },
    {
      id: "4",
      tag: "Solidity, Hardhat",
      title: "MultiSig Wallet Dapp",
      img: work5,
      imgSmall: workSmall5,
      bg: "#FFF0F0",
      langages: "Solidity, Hardhat, ReactJS",
      link: "https://multisigzz.surge.sh/",
      linkText: "Demo",
      description:
        "Multisig stands for multi-signature, which is a specific type of digital signature that makes it possible for two or more users to sign documents as a group.",
      gitUrl: "https://github.com/Hussainzz/Multi-Sig"
    },
    {
      id: "5",
      tag: "NodeJS, ReactJS, SocketIO, MongoDB",
      title: "Gogo App",
      img: work4,
      imgSmall: workSmall4,
      bg: "#E9FAFF",
      link: "https://gogo-appz.herokuapp.com/",
      linkText: "Demo",
      description:"A Basic web app which allows users to create rooms, join rooms and chat and share images in-realtime",
      gitUrl: "https://github.com/Hussainzz/GogoApp"
      },
      
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: "",
      imgSmall: blogSmall1,
      date: "3 Sept 2022",
      category: "Web3",
      title: "Lazy Minting NFT - Solidity, Hardhat",
      bg: "#FCF4FF",
      url: "https://dev.to/hussainzz/lazy-minting-nft-solidity-hardhat-30l1",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    },
    {
      id: "2",
      img: "",
      imgSmall: blogSmall4,
      date: "28 March 2022",
      category: "Web3",
      title: "Eth-Lottery - Solidity, Hardhat.",
      bg: "#EEFBFF",
      url: "https://dev.to/hussainzz/eth-lottery-solidity-hardhat-3ebg",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum conseqr quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consetur quo obcaecati rerum sit non. Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur. \n Lorem ipsum dolor, sit amet consectetur adipisicing  elit. Fuga consequatur delectus porro sapiente molestias, magni quasi sed, enim corporis omnis doloremque soluta inventore dolorum consequuntur quo obcaecati rerum sit non. ",
    }
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/homePage",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "Works",
      link: "/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "03",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "04",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "05",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
  ];

  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: "01",
      name: "About",
      link: "/homeTwo/about",
      icon: <FaRegUser />,
    },
    {
      id: "02",
      name: "Resume",
      link: "/homeTwo/resume",
      icon: <CgNotes />,
    },
    {
      id: "03",
      name: "Works",
      link: "/homeTwo/works",
      icon: <FiCodesandbox />,
    },
    {
      id: "04",
      name: "Blogs",
      link: "/homeTwo/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/homeTwo/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  // Slider image for Clients
  const sliderImg = [
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon5,
      title: "Web Development",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
    {
      id: "2",
      icon: icon1,
      title: "Smart Contracts",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    }
  ];


  const skillsArray = [
    "JavaScript", "NodeJS", "Express", "Solidity", "Hardhat Framework", "ReactJS", "ElasticSearch", "PHP", "Laravel", "Codeigniter"
  ];

  // Resume items for Resume page
  const resumeAchievements = [
    {
      type: "Achievements",
      icon: FaAward,
      id: 1,
      date: "2022",
      title: "Top 40 Quality Project Winner",
      place: "Chainlink Spring 2022 Hackathon",
      bg: "#FCF4FF"
    },
    {
      type: "Achievements",
      icon: FaAward,
      id: 2,
      date: "2022",
      title: ["Polygon Pool Prize", "Sponsor Prizes | Filecoin/IPFS Prizes | Pool Prize"],
      place: "Polygon BUIDL IT : Summer 2022 Hackathon",
      bg: "#FCF4FF"
    }
  ];

  const resumeExperience = [
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: 1,
      date: "2018-Present",
      title: "Software Developer",
      place: "Intuio Software Labs Pvt Ltd, Margao, Goa, India",
      bg: "#EEF5FA"
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: 2,
      date: "Final year project",
      title: "Internship at SINGULERP Starup",
      place: "SINGUL-ERP.",
      bg1: "#F2F4FF"
    }
  ];

  const resumeEduction = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: 1,
      date: "2016-2018",
      title: "Msc IT (Computer Science) - GOA Univeristy",
      place: "Parvatibai Chowgule College Of Arts & Science, Margao, Goa, India",
      bg: "#FFF4F4"
    },
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: 2,
      date: "2013 - 2016",
      title: "Bachelor of computer application - GOA University",
      place: "Fr.Agnel College Of Arts & Commerce",
      bg: "#FFF1FB"
    }
  ]

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Web Design",
      number: "80",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Mobile App ",
      number: "95",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Illustrator",
      number: "65",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Photoshope",
      number: "75",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+452 666 386",
      item2: "+452 666 386",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "support@gmail.com",
      item2: "example@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Address ",
      item1: "Maount View, Oval",
      item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ];

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    menuItemTwo,
    experienceArray,
    sliderImg,
    resumeAchievements,
    lineArray,
    contactArray,
    skillsArray,
    resumeEduction,
    resumeExperience
  };
};

export default AllData;
